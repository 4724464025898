<!-- 审核弹窗 -->
<template>
  <div class="">
    <el-dialog
      title="添加积分调整"
      :visible.sync="dialogAdd"
      width="30%"
      :z-index="12"
      :close-on-click-modal="false"
    >
      <ax-form ref="formBox" :formBuilder="formBuilder"> </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal()">取 消</el-button>
        <el-button
          style="background-color: #556bff; color: #fff"
          :loading="saveType"
          @click="Submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "../api";
const formList = [
  {
    label: "类别",
    type: "select",
    model: "roleType",
    options: {
      placeholder: "请选择",
      options: [
        { label: "参加社区活动", value: "1" },
        { label: "完成社区活动评分", value: "2" },
        { label: "参加志愿者活动", value: "3" },
        { label: "居民点单", value: "4" },
        { label: "其他", value: "7" },
      ],
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "活动/类别名称",
    type: "input",
    model: "businessName",
    options: {
      placeholder: "请输入",
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "分值",
    type: "inputNumber",
    model: "points",
    options: {
      placeholder: "请输入",
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请选择" }],
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      dialogAdd: false,
      saveType: false,
      title: "添加积分规则",
      type: "",
      // 弹窗表单
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      console.log(record);
      if (record.activeTab[0] === "1") {
        this.type = "0";
      } else if (record.activeTab[0] === "2") {
        this.type = "1";
      }
      this.dialogAdd = true;
      this.saveType = false;
      this.$nextTick(() => {
        this.owningGrid();
      });
    },
    async owningGrid() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.formBox.setFormItemProp("gridId", {
            options: { options, allowClear: true, placeholder: "请选择",changeOnSelect:true },
          });
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 根据审核意见显示不同的字段
    Submit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        let obj = {
          type: this.type,
          roleType: value.roleType,
          businessName: value.businessName,
          points: value.points,
          gridId: value.gridId[value.gridId.length - 1],
        };
        try {
          const res = await api.addRule(obj);
          if (res.status == 200) {
            this.$message.success("操作成功");
            this.closeModal();
            this.saveType = false;
            this.$emit("refresh");
          } else {
            this.$message.error("操作失败");
            this.saveType = false;
          }
          this.saveType = false;
        } catch (error) {
          console.error(error);
          this.saveType = false;
        }
      });
    },
    closeModal() {
      this.dialogAdd = false;
      this.$refs.formBox.resetFields();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>