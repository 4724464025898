import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取常住人口列表数据
  list: params => get('/scPointsRole/list', params),
   // 网格树
   gridTree:args => get('/scGridManagement/getTreeList', args),
  //  新增规则
   addRule:args => post('/scPointsRole/add', args),
  
  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
